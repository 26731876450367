// 公用api
import request from '@/tools/request';

// 积分配置列表
export function getList(data, loading) {
  return request({
    url: `/buypoints-front-List.html`,
    method: 'get',
    params: data,
    loading,
  });
}
// 获取积分配置
export function getGoodsPoint(data, loading) {
  return request({
    url: `/buypoints-front-GetGoodsPoint.html`,
    method: 'get',
    params: data,
    loading,
  });
}
// 获取发货配置
export function getConfig(data) {
  return request({
    url: `/buypoints-front-GetConfig.html`,
    method: 'get',
    params: data,
  });
}
// 删除商品积分配置
export function delConfig(data, loading) {
  return request({
    url: `/buypoints-front-Del.html`,
    method: 'post',
    data,
    loading,
  });
}
// 设置发货配置
export function setConfig(data) {
  return request({
    url: `/buypoints-front-setConfig.html`,
    method: 'post',
    data,
  });
}
// 获取商品规格
export function getSku(data) {
  return request({
    url: `/component-goodsSearch-getSku.html`,
    method: 'get',
    params: data,
  });
}
// 保存商品积分配置
export function SetGoodsPoint(data) {
  data = JSON.stringify(data);
  return request({
    url: `/buypoints-front-SetGoodsPoint.html`,
    method: 'post',
    data,
  });
}
