<template>
  <div>
    <div class="main">
      <p>积分发送设置</p>
      <div class="raido_grop">
        <el-radio-group v-model="radio">
          <el-radio :label="'order_ship'">订单发货时发送</el-radio>
          <el-radio :label="'order_pay'">支付后自动发送</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="btnok">
      <el-button type="primary" :loading="loading" @click="Setting">保存</el-button>
    </div>
  </div>
</template>
<script>
import { getConfig, setConfig } from '../../api/buypoints';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';

export default {
  setup(props, context) {
    const radio = ref('order_ship');
    const loading = ref(false);

    async function Config() {
      const { data } = await getConfig();
      if (!data.event_name) {
        //为空手动调用赋值一条数据
        const parms = {
          event_name: radio.value,
        };
        setConfig(parms).then(() => {});
      } else {
        radio.value = data.event_name;
      }
    }

    function Setting() {
      if (!radio.value) {
        return ElMessage.warning({
          message: '请选积分发送设置!',
          type: 'warning',
        });
      }
      loading.value = true;
      const parms = {
        event_name: radio.value,
      };
      setConfig(parms)
        .then(() => {
          loading.value = false;
          ElMessage.success({
            message: '设置成功',
            type: 'success',
          });
          context.emit('upDate', 0);
        })
        .catch(() => {
          loading.value = false;
        });
    }
    return {
      radio,
      Config,
      loading,
      Setting,
    };
  },
  mounted() {
    this.Config();
  },
};
</script>

<style scoped lang="scss">
.main {
  padding: 15px 20px;

  p {
    font-size: 16px;
  }

  .raido_grop {
    padding: 37px 0 50px 0;
  }
}

.btnok {
  width: 100%;
  background-color: #fafafa;
  padding: 10px 20px;
  box-sizing: border-box;
  button {
    min-width: 140px;
  }
}
</style>
