<template>
  <div class="home">
    <header>
      <m-page-header :pageTitle="pageTitle" @upDate="upDate" />
    </header>
    <main>
      <integralList v-if="isActive === 0" />
      <sendSeeting v-else @upDate="upDate" />
    </main>
  </div>
</template>

<script>
import MPageHeader from '../components/pageHeader/PageHeader.vue';
import integralList from '../components/integralList/Index.vue';
import sendSeeting from '../components/sendSeeting/Index.vue';
import { ref } from 'vue';
export default {
  name: 'BuyPoints',
  components: {
    'm-page-header': MPageHeader,
    integralList,
    sendSeeting,
  },
  setup() {
    let pageTitle = ref([
      {
        text: '积分列表',
        isavtive: true,
      },
      {
        text: '发货设置',
        isavtive: false,
      },
    ]);
    let isActive = ref(0);
    function upDate(index) {
      isActive.value = index;
      pageTitle.value.map(res => {
        res.isavtive = false;
      });
      pageTitle.value[index].isavtive = true;
    }
    return {
      pageTitle,
      upDate,
      isActive,
    };
  },
};
</script>

<style scoped></style>
