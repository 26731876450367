<template>
  <div class="main">
    <div class="mainHeader">
      <div class="left">
        <el-input
          placeholder="请输入商品名称或者编号 "
          clearable
          v-model="pages.keyword"
          size="small"
          class="input-with-select"
        >
          <template #append>
            <el-button icon="el-icon-search" @click="getDataList"></el-button>
          </template>
        </el-input>
      </div>
      <div class="right">
        <router-link :to="getRoute('buy-points-integral')" class="config-link-wrap">
          <el-button type="primary" size="small">新建积分</el-button>
        </router-link>
      </div>
    </div>
    <div class="list-wrap">
      <m-table
        :data="dataList"
        :loading="listLoading"
        @selection-change="changeSelection"
        :control="[
          {
            text: '批量删除',
            on: 'batchSync',
            loading: batchSyncLoading,
          },
        ]"
        @batchSync="deldetGoods('batch', selectionIds)"
        :pagination="{
          total: pages.total,
          pageSize: pages.page_size,
          currentPage: pages.page,
        }"
        @currentChange="handleCurrentChange"
        ref="mTable"
      >
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
        <el-table-column type="selection"> </el-table-column>
        <el-table-column label="商品">
          <template #default="tableItem">
            <div class="good_list">
              <div class="shoppy_img">
                <a target="_blank" :href="tableItem.row.goods_detail_url">
                  <el-image
                    style="width: 70px; max-height: 90px; border-radius: 5px"
                    :src="tableItem.row.image"
                    fit="fill"
                  >
                    <template #error>
                      <div class="image-slot">
                        <img src="../../../../assets/img/goodserro.png" alt="" />
                      </div>
                    </template>
                  </el-image>
                </a>
              </div>
              <div>
                <a
                  class="base_name"
                  target="_blank"
                  :href="tableItem.row.goods_detail_url"
                  :title="tableItem.row.base_name"
                  >{{ tableItem.row.base_name }}</a
                >
                <p class="number">{{ tableItem.row.category_name }}</p>
                <p class="number">{{ tableItem.row.codeno }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="220px">
          <template #default="tableItem">
            <el-tag v-if="tableItem.row.status === 1">{{ tableItem.row.stasu_name }}</el-tag>
            <el-tag type="danger" v-else>{{ tableItem.row.stasu_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="price" width="150px"></el-table-column>
        <el-table-column label="操作" width="220px">
          <template #default="tableItem">
            <el-button size="small" @click="edit(tableItem.row)">编辑</el-button>
            <el-button
              size="small"
              :loading="tableItem.row.loading"
              @click="deldetGoods('single', tableItem.row.primary_id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </m-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { getRoute } from '@/tools/config';
import { getList, delConfig } from '../../api/buypoints';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
export default defineComponent({
  setup() {
    // 初始化 列表数据
    const dataList = ref([]);
    const listLoading = ref(false);
    const batchSyncLoading = ref(false);
    const selectionIds = ref([]);
    const selectionRow = ref([]);
    const mTable = ref();
    let pages = ref({
      page: 1,
      page_size: 10,
      total: 0,
      keyword: '', //商品名称或者编号
    });
    const router = useRouter();
    async function getDataList() {
      mTable.value.closeControlBar();
      const parms = {
        page: pages.value.page,
        page_size: pages.value.page_size,
        keyword: pages.value.keyword,
      };
      const { data } = await getList(parms, listLoading);
      data.list.map(res => {
        res.price = data.currency.symbol_left + res.price + ' ' + data.currency.symbol_right;
      });
      dataList.value = data.list;
      pages.value.total = data.pages.total;
    }
    const changeSelection = val => {
      selectionIds.value = val
        .map(item => {
          return item.primary_id;
        })
        .join(',');
      selectionRow.value = val;
    };
    function handleCurrentChange(val) {
      pages.value.page = val;
      getDataList();
    }
    /*function handleSizeChange(val) {
      pages.value.page_size = val;
      getDataList();
    }*/
    function edit(row) {
      router.push(`buy-points-integral/${row.id}/${row.status}`);
    }
    function deldetGoods(type, id) {
      if (type === 'batch') {
        if (selectionRow.value.length <= 0) {
          return ElMessage.warning({
            message: '请至少选择一条数据删除!',
            type: 'warning',
          });
        }
      }
      this.$confirm('删除当前商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deldet(id);
        })
        .catch(() => {});
    }
    function deldet(id) {
      const parms = {
        ids: id,
      };
      delConfig(parms, batchSyncLoading)
        .then(() => {
          ElMessage.success({
            message: '删除成功!',
            type: 'success',
          });
          getDataList();
          mTable.value.closeControlBar();
        })
        .catch(() => {});
    }

    return {
      getRoute,
      dataList,
      listLoading,
      batchSyncLoading,
      router,
      pages,
      changeSelection,
      getDataList,
      handleCurrentChange,
      deldetGoods,
      mTable,
      selectionRow,
      selectionIds,
      edit,
    };
  },
  mounted() {
    this.getDataList();
  },
});
</script>

<style scoped lang="scss">
.main {
  padding: 15px 20px;
  .mainHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.list-wrap {
  margin-top: 15px;
  .good_list {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      flex: 1;
      p,
      .base_name {
        color: #000;
        overflow: hidden;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .base_name,
      .base_name:visited {
        color: #0000ee;
      }
      .number {
        padding: 5px 0 3px 0;
        box-sizing: border-box;
      }
    }
    .shoppy_img {
      flex: 0 0 80px;
      a {
        display: inline-block;
      }
    }
  }
}
</style>
